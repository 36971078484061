import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14edfc15"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('coralCertDetails'),
        parentPath: "/certificates"
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ion_spinner)
                  ]))
                : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        class: "ion-text-center",
                        "size-xs": "12",
                        "size-md": "6"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _withDirectives(_createVNode(_component_ion_skeleton_text, {
                              animated: true,
                              style: {"width":"100%","height":"350px"}
                            }, null, 512), [
                              [_vShow, !$setup.certificate.imgLoaded]
                            ]),
                            _createElementVNode("img", {
                              class: "rounded-img",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openImageModal($setup.certificate.photoLink, $setup.certificate.coralId))),
                              onLoad: _cache[1] || (_cache[1] = ($event: any) => ($setup.certificate.imgLoaded = true)),
                              src: $setup.addResizeUrlParams($setup.certificate.coralPhotoLink, 600)
                            }, null, 40, _hoisted_2)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card, { class: "info-card primary-border" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_header, { class: "ion-text-center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    class: "verified-icon",
                                    icon: $setup.shieldCheckmark,
                                    color: "success"
                                  }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_card_title, { color: "primary" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('verifiedCertInfo')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('certificateNumber')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.certificateNumber), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralId')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.coralId), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralGenus')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.certificate.coralGenus), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralSpecies')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.coralSpecies), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _withDirectives(_createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralSize')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.coralSize), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 512), [
                                    [_vShow, $setup.certificate.coralSize]
                                  ]),
                                  _withDirectives(_createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralGrade')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.coralGrade), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 512), [
                                    [_vShow, $setup.certificate.coralGrade]
                                  ]),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralOrigin')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.coralOrigin), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('importDate')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.importDate), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('citesPermitNo')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.citesPermitNo), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('importLicenseNo')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.importLicenseNo), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('coralOwner')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, {
                                        color: "primary",
                                        slot: "end"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString($setup.certificate.ownerName), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.t('acquisitionDate')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_chip, { slot: "end" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString($setup.certificate.acquisitionDate), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}