
// Vue reactivity
import { computed, onMounted, ref, watch, } from 'vue';

// icons
import { person, call, mail, refresh, clipboardOutline, checkmark, close,
          camera, shieldCheckmark, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
        IonList, IonListHeader, IonInput, IonTextarea, IonText, IonChip, IonBadge, IonImg,
        IonSkeletonText, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import CommonService from '@/services/CommonService';

export default {
  name: 'CertDetailPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonThumbnail,
                IonList, IonListHeader, IonInput, IonTextarea, IonText, IonChip, IonBadge, IonImg,
                IonSkeletonText, },
  setup() {
    // Composables
    const store = useStore();
    const { t, locale } = useI18n();
    const { formatDate, presentAlert, openImageModal, addResizeUrlParams, } = utils();

    // Route params
    const router = useRouter();
    const route = useRoute();
    const { certId } = route.params; // entered from certificate list page
    const { id: uuid } = route.query; // enter directly from QR code

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppUserData);
    const certificate = ref({});

    const presentInvalidCertAlert = async () => {
      presentAlert(t('invalidCert'));
      router.replace('/certificates');
    }

    onMounted(() => {
      if (certId) {
        certificate.value = store.getters.getCertificateById(certId);
      }
      else if (uuid) {
        CommonService.getCertificateByUUID(uuid).then(res => {
          if (res) {
            certificate.value = res;
          } else {
            presentInvalidCertAlert();
          }
        })
      }
    })
/*
    watch(certificate, (currCert) => {
      if (!currCert.id) {
        presentInvalidCertAlert();
      }
    })
*/

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      person, call, mail, refresh, clipboardOutline, checkmark, close, camera,
      shieldCheckmark,

      // variables
      locale, certId, loading, certificate,

      // methods
      t, formatDate, openImageModal, addResizeUrlParams,
    }
  },
}
